import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/(info)/app-shared/privacy-policy": [21,[5]],
		"/(info)/app-shared/terms-and-conditions": [22,[5]],
		"/(login-signup)/apple-redirect": [27,[6]],
		"/(app)/clients": [8,[2]],
		"/(app)/clients/[clientId]": [9,[2]],
		"/(app)/clients/[clientId]/details": [10,[2]],
		"/(app)/clients/[clientId]/notes/[sessionId]": [11,[2]],
		"/(app)/feedback": [12,[2,3]],
		"/(login-signup)/forgot-password": [28,[6]],
		"/(app)/help": [13,[2]],
		"/(app)/info/privacy-policy": [14,[2]],
		"/(app)/info/terms-and-conditions": [15,[2]],
		"/(login-signup)/login": [29,[6]],
		"/(app)/manage-fields": [16,[2]],
		"/(info)/privacy-policy": [23,[4]],
		"/(info)/request-delete-account": [24,[4]],
		"/(info)/request-delete-data": [25,[4]],
		"/(login-signup)/reset-password/[uid]/[token]": [30,[6]],
		"/(app)/settings": [17,[2]],
		"/(app)/settings/account": [18,[2]],
		"/(app)/settings/subscription": [19,[2]],
		"/(login-signup)/signup": [31,[6]],
		"/(app)/templates": [20,[2]],
		"/(info)/terms-and-conditions": [26,[4]],
		"/(login-signup)/verify-email/[token]": [32,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';